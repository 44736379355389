import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomepagePage } from './homepage/homepage.page';
import { ArticlesComponent } from './articles/articles.component';
import { ComparisonComponent } from './comparison/comparison.component';
import { PricingPageComponent } from './pricing-page/pricing-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { ResourcesComponent } from './resources/resources.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { UsersPage } from './users/users.page';
import { AuthGuard } from './guards/auth.guard'

const routes: Routes = [
  {
    path: '',
    component: HomepagePage, 
    // redirectTo: 'homepage',
    // pathMatch: 'full', 
    // canActivate: [AuthGuard] 
  },

  { 
    path: 'country-report', 
    component: ArticlesComponent, 
    // canActivate: [AuthGuard] 
  },
  { 
    path: 'comparison', 
    component: ComparisonComponent, 
    // canActivate: [AuthGuard] 
  },
  {
    path: 'users',
    component: UsersPage 
    // loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule), 
    // canActivate: [AuthGuard]
  },
  {
    path: 'resources',
    component: ResourcesComponent 
  },
  {
    path: 'pricing',
    component: PricingPageComponent 
  },
  {
    path: 'contact',
    component: ContactPageComponent 
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'cookies-policy',
    component: CookiesPolicyComponent 
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent 
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent 
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
