import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { DisplayModalComponent } from '../utils/display-modal/display-modal.component'
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';

import { AskFormComponent } from '../ask-form/ask-form.component';
import { BookDemoComponent } from '../book-demo/book-demo.component';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.page.html',
  styleUrls: ['./homepage.page.scss'],
})
export class HomepagePage implements OnInit {
  latestUpdates;

  constructor(private modalController: ModalController, private youtube: YoutubeVideoPlayer, private firestore: AngularFirestore) { }

  ngOnInit() {
    this.getLatestUpdates()
  }

  async presentVideoModal() {
    this.youtube.openVideo('HtMR9_lZMn8');
    // let articleText = 'http://www.youtube.com/watch?v=HtMR9_lZMn8'
    // const modal = await this.modalController.create({
    //   component: DisplayModalComponent,
    //   componentProps: { 
    //     text: articleText
    //   }
    // });
    // return await modal.present();
  }

  getLatestUpdates() {
    this.latestUpdates =[]
    this.firestore.collection('homepage/latestUpdates/updates').snapshotChanges()
    .subscribe(
      (res)=>{
        this.latestUpdates = []
        res.forEach(
          (update)=>{
            let temp = {}
            temp['id'] = update.payload.doc.id
            temp['data'] = update.payload.doc.data()
            temp['data']['date_time'] = temp['data']['date_time'].toDate()
            this.latestUpdates.push(temp)
          }
        )
        this.latestUpdates.sort(function (a, b) {
          // console.log('Sorting', a)
          if (a['data']['date_time'] > b['data']['date_time']) {
              return -1;
          }
          if (b['data']['date_time'] > a['data']['date_time']) {
              return 1;
          }
          return 0;
        })
      }
    )
  }

  async showAskAnExpert(){
    const modal = await this.modalController.create({
      component: AskFormComponent
    });
    return await modal.present()
  }

  async bookDemo(){
    const modal = await this.modalController.create({
      component: BookDemoComponent
    });
    return await modal.present()
  }

  

}
