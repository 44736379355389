import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ViewportScroller } from '@angular/common'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { IonReorderGroup } from '@ionic/angular';
import {map, take} from 'rxjs/operators'
import { Pipe, PipeTransform } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonContent } from '@ionic/angular';

import { TextareaAutosizeDirective } from '../directives/textarea-autosize.directive';

import { MainMenuComponent } from '../menus/main-menu/main-menu.component'
import { AuthService } from '../services/auth.service'


@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit {
  @ViewChild(IonContent, { static: false }) content: IonContent;

  resources
  articles
  webinars
  trainings
  videos = []
  selectedCountry
  currentUser
  currentUserAditionalData

  constructor(private firestore: AngularFirestore, private firebaseAuth: AngularFireAuth, public modalController: ModalController, 
    private authService: AuthService, private viewportScroller: ViewportScroller) {
    
  }
  


  ngOnInit() {
    this.getArticles() 
    this.getVideos()
    this.getTrainings()
    this.getWebinars()
  }

  getArticles(){
    this.firestore.collection('resources/article/articles').snapshotChanges()
    .subscribe(
      (res)=>{
        this.articles = []
        res.forEach(
          r=>{
            let art = {}
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc.id
            this.articles.push(art)
          }
        )
        console.log('resources ', this.resources)
      }
    )
  }

  getVideos(){
    this.firestore.collection('resources/video/videos').snapshotChanges()
    .subscribe(
      (res)=>{
        this.videos = []
        res.forEach(
          r=>{
            let art = {}
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc.id
            this.videos.push(art)
          }
        )
        console.log('videos ', this.videos)
      }
    )
  }

  getWebinars(){
    this.firestore.collection('resources/webinar/webinars').snapshotChanges()
    .subscribe(
      (res)=>{
        this.webinars = []
        res.forEach(
          r=>{
            let art = {}
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc.id
            this.webinars.push(art)
          }
        )
        console.log('webinars ', this.webinars)
      }
    )
  }

  getTrainings(){
    this.firestore.collection('resources/training/trainings').snapshotChanges()
    .subscribe(
      (res)=>{
        this.trainings = []
        res.forEach(
          r=>{
            let art = {}
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc.id
            this.trainings.push(art)
          }
        )
        console.log('trainings ', this.trainings)
      }
    )
  }

  scroll(id){
    var el = document.getElementById(id);
    this.content.scrollToPoint(0, el.offsetTop - 72, 1000);
    // this.content.sc
  }

}
