import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { RegisterComponent } from '../register/register.component';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';

@Component({
  selector: 'app-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss'],
})
export class PricingPageComponent implements OnInit {
  
  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  async presentModal(pachet) {
    const modal = await this.modalController.create({
      component: RegisterComponent,
      componentProps: {
        'pachet': pachet
      }
    });
    return await modal.present();
  }

  async requestAQuote(requestQuoteOn) {
    const modal = await this.modalController.create({
      component: RequestQuoteComponent,
      componentProps: {
        'requestQuoteOn': requestQuoteOn
      }
    });
    return await modal.present();
  }
  

}
