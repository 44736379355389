import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { HttpClientModule } from '@angular/common/http';

import { environment } from '../environments/environment';

import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { TextareaAutosizeDirective } from './directives/textarea-autosize.directive';
import { NgxStripeModule } from 'ngx-stripe';
import { StripeCheckoutModule } from 'ng-stripe-checkout';

import { ArticlesComponent } from './articles/articles.component';
import { ComparisonComponent } from './comparison/comparison.component';
import { UsersPage } from './users/users.page';
import { AddFieldPageModule } from './articles/add-field/add-field.module';
import { AddSectionPageModule } from './articles/add-section/add-section.module';
import { AddCountryPageModule } from './articles/add-country/add-country.module';
import { ShowFieldHistoryPageModule } from './articles/show-field-history/show-field-history.module';
import { MainMenuComponent } from './menus/main-menu/main-menu.component'
import { MobileMenuComponent } from './menus/mobile-menu/mobile-menu.component'
import { AskComponent } from './ask/ask.component'
import { AskFormComponent } from './ask-form/ask-form.component'
import { RequestQuoteComponent } from './request-quote/request-quote.component';
import { BookDemoComponent } from './book-demo/book-demo.component'
import { PricingPageComponent } from './pricing-page/pricing-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { RegisterComponent } from './register/register.component';
import { HomepagePage } from './homepage/homepage.page';
import { ContactComponent } from './contact/contact.component';
import { ResourcesComponent } from './resources/resources.component';
import { FooterComponent } from './footer/footer.component';
import { DisplayModalComponent } from './utils/display-modal/display-modal.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { Login2Page } from './login2/login2.page';
import { PaymentComponent } from './payment/payment.component';

import { AuthService } from './services/auth.service';

@NgModule({
  declarations: [AppComponent,
    ArticlesComponent,
    ComparisonComponent,
    HomepagePage,
    MainMenuComponent,
    MobileMenuComponent,
    AskComponent,
    AskFormComponent,
    UsersPage,
    PricingPageComponent,
    ContactPageComponent,
    RegisterComponent,
    DisplayModalComponent,
    ContactComponent,
    FooterComponent,
    ResourcesComponent,
    CookiesPolicyComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    BookDemoComponent,
    Login2Page,
    RequestQuoteComponent,
    TextareaAutosizeDirective,
    PaymentComponent
  ],
  entryComponents: [
    AskFormComponent,
    RegisterComponent,
    DisplayModalComponent,
    BookDemoComponent,
    RequestQuoteComponent,
    PaymentComponent
  ],
  imports: [
    FormsModule, ReactiveFormsModule,
    BrowserModule, HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'angular-auth-firebase'),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AddFieldPageModule,
    AddSectionPageModule,
    AddCountryPageModule,
    ShowFieldHistoryPageModule,
    AngularMultiSelectModule,
    NgxStripeModule.forRoot('pk_test_2G6eiAqcABC7UffxjJHxu0PP00QQRN98KL'),
    StripeCheckoutModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService,
    YoutubeVideoPlayer,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
