import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login2',
  templateUrl: './login2.page.html',
  styleUrls: ['./login2.page.scss'],
})
export class Login2Page implements OnInit {

  user = {email: '', password: ''}
  constructor(public afAuth: AngularFireAuth, private router: Router, private authService: AuthService) { }

  ngOnInit() {
  }

  signInWithFacebook() {
    this.authService.signInWithFacebook()
    .then((res) => { 
        // this.router.navigate(['articles'])
        this.router.navigateByUrl('/articles');
      })
    .catch((err) => console.log(err));
  }

  signInWithEmail() {
    this.authService.signInRegular(this.user.email, this.user.password)
       .then((res) => {
          console.log('[Login Page] [signInWithEmail] success', res);
          this.router.navigate(['/']);
       })
       .catch((err) => console.log('error: ' + err));
 }

  // loginWithEmail(){
  //   this.afAuth.signInWithEmailAndPassword(this.user.email, this.user.password)
  //     .then(
  //       (res)=>{
  //         console.log('[Login] loginWithEmail', res)
  //         this.router.navigate(['articles'])
  //         .then(
  //           (res)=>{ console.log('Routing successfull ', res)}
  //         )
  //         .catch(
  //           (err)=>{ console.log('Routing error ', err)}
  //           )
  //       }
  //     )
  //     .catch(
  //       (err)=>{
  //         alert('Incorrect username or password')
  //       }
  //     )
  // }

}
