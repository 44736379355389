import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-country',
  templateUrl: './add-country.page.html',
  styleUrls: ['./add-country.page.scss'],
})
export class AddCountryPage implements OnInit {

  name
  
  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }

  async returnData(){
    this.modalController.dismiss(this.name)
  }
}
