import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-show-field-history',
  templateUrl: './show-field-history.page.html',
  styleUrls: ['./show-field-history.page.scss'],
})
export class ShowFieldHistoryPage implements OnInit {
  fieldPath
  history

  constructor(private firestore: AngularFirestore, private modalController: ModalController) { }

  ngOnInit() {
  // console.log('History modal', this.fieldPath )
  this.firestore.collection(this.fieldPath + '/history').valueChanges()
  .subscribe(
    (res)=>{
      this.history = res
      this.history.forEach(
        (r)=>{
          // console.log(r.dateTime)
           r['date_time'] =r.dateTime.toDate()
          r.action = r.action.toUpperCase()
          if (!r.user.photoURL){
            r.user.photoURL = "/assets/profile-placeholder.jpg"
          }
        }
      )
      this.history.sort(function (a, b) {
        if (a.date_time > b.date_time) {
            return -1;
        }
        if (b.date_time > a.date_time) {
            return 1;
        }
        return 0;
      })
    }
  )

  }

  dismissModal(){
    this.modalController.dismiss()
  }

}
