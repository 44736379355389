import { Component, OnInit } from '@angular/core';
import {
  HttpClient, HttpEvent, HttpEventType, HttpProgressEvent,
  HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders
} from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';


@Component({
  selector: 'contact-component',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {

  constructor( private http: HttpClient ) {

   }

  emailData={name:'', email:'', body:''}

  ngOnInit() {}

  sendContactMail(){
    console.log('[Contact] SendContactMail')
  
    const options = {}
    options['name'] = this.emailData.name
    options['email'] = this.emailData.email
    options['subject'] = 'New message from dac6guide.eu'
    options['body'] = this.emailData.body;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    if(!this.emailData.name || !this.emailData.body || !this.emailData.email){
      alert('Please fill all the fields before sending!')
    }
    else{
      options['body'] += '<br><br><br>Message received through dac6guide.eu<br>'
      options['body'] += 'From:' + this.emailData.name + '<br>'
      options['body'] += 'Email:' + this.emailData.email + '<br>'
      this.http.post(
          'https://sendemail.dac6guide.eu/sendmail.php', options, httpOptions
      )
      .subscribe(
        res => {
          console.log('Mail Result:' , res)
          this.emailData={name:'', email:'', body:''}
          alert('Thank you for your message. We will get back to you in a very short time!')
          // this.showAlert('All good', 'An email was sent to the user with instructions to create their account')
        },
        err => {
          alert('Your message could not be sent! Please try again')
          console.log('Mail Error:' , err)
          // this.showAlert('Error', 'User data wasnt completly saved! Please contact your system administrator!')
        }
      );
    }
}

}
