import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-display-modal',
  templateUrl: './display-modal.component.html',
  styleUrls: ['./display-modal.component.scss'],
})
export class DisplayModalComponent implements OnInit {
  
  @Input() text;
  constructor(private dom: DomSanitizer, private modalController:ModalController) { }

  ngOnInit() {
  }

  dismissModal(){
    this.modalController.dismiss()
  }

}
