import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AskFormComponent } from '../ask-form/ask-form.component';

@Component({
  selector: 'app-ask',
  templateUrl: './ask.component.html',
  styleUrls: ['./ask.component.scss'],
})
export class AskComponent implements OnInit {
  @Input() layout: string;
  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  async presentModal() {
    const modal = await this.modalController.create({
      component: AskFormComponent
    });
    return await modal.present()
    
  }

  

}



