import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-section',
  templateUrl: './add-section.page.html',
  styleUrls: ['./add-section.page.scss'],
})
export class AddSectionPage implements OnInit {

  name

  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }

  async returnData(){
    this.modalController.dismiss(this.name)
  }

}
